export const OPEN_ASSIGN_REVIEWER_MODAL = 'OPEN_ASSIGN_REVIEWER_MODAL'
export const CLOSE_ASSIGN_REVIEWER_MODAL = 'CLOSE_ASSIGN_REVIEWER_MODAL'
export const SET_AVAILABLE_REVIEWERS = 'SET_AVAILABLE_REVIEWERS'
export const SET_AVAILABLE_CONTACTS = 'SET_AVAILABLE_CONTACTS'
export const SET_IS_LOADING_SUBMIT_ASSIGNMENT = 'SET_IS_LOADING_SUBMIT_ASSIGNMENT'
export const SET_AVAILABLE_MONITORS = 'SET_AVAILABLE_MONITORS'
export const SET_AVAILABLE_OBSERVERS = 'SET_AVAILABLE_OBSERVERS'
export const SET_AVAILABLE_CONSULTANTS = 'SET_AVAILABLE_CONSULTANTS'
export const SET_IS_ASSIGN_ATC_UNITS_SUCCESS = 'SET_IS_ASSIGN_ATC_UNITS_SUCCESS'
export const OPEN_ASSIGN_ATC_UNITS_MODAL = 'OPEN_ASSIGN_ATC_UNITS_MODAL'
export const CLOSE_ASSIGN_ATC_UNITS_MODAL = 'CLOSE_ASSIGN_ATC_UNITS_MODAL'
export const SET_IS_AUTHORITIES_LOADED = 'SET_IS_AUTHORITIES_LOADED'
export const SET_IS_REVIEWERS_LOADED = 'SET_IS_REVIEWERS_LOADED'
