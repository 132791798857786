import * as types from './template-revision.types'

export const initialState = {
  isAssignReviewerModalOpen: false,
  isAssignATCUnitsModalOpen: false,
  isLoadingSubmitAssignment: false,
  isAssignATCUnitsSuccess: false,
  availableReviewers: [],
  availableContacts: [],
  availableObservers: [],
  availableMonitors: [],
  availableConsultants: [],
  isAuthoritiesLoaded: false,
  isReviewersLoaded: false,
}

export function templateRevisionReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLOSE_ASSIGN_REVIEWER_MODAL:
      return {
        ...state,
        isAssignReviewerModalOpen: false,
      }
    case types.OPEN_ASSIGN_REVIEWER_MODAL:
      return {
        ...state,
        isAssignReviewerModalOpen: true,
      }
    case types.CLOSE_ASSIGN_ATC_UNITS_MODAL:
      return {
        ...state,
        isAssignATCUnitsModalOpen: false,
      }
    case types.OPEN_ASSIGN_ATC_UNITS_MODAL:
      return {
        ...state,
        isAssignATCUnitsModalOpen: true,
      }
    case types.SET_AVAILABLE_REVIEWERS:
      return {
        ...state,
        availableReviewers: action.availableReviewers,
      }
    case types.SET_AVAILABLE_CONTACTS:
      return {
        ...state,
        availableContacts: action.availableContacts,
      }
    case types.SET_AVAILABLE_OBSERVERS:
      return {
        ...state,
        availableObservers: action.availableObservers,
      }
    case types.SET_AVAILABLE_MONITORS:
      return {
        ...state,
        availableMonitors: action.availableMonitors,
      }
    case types.SET_AVAILABLE_CONSULTANTS:
      return {
        ...state,
        availableConsultants: action.availableConsultants,
      }
    case types.SET_IS_LOADING_SUBMIT_ASSIGNMENT:
      return {
        ...state,
        isLoadingSubmitAssignment: action.status,
      }
    case types.SET_IS_ASSIGN_ATC_UNITS_SUCCESS:
      return {
        ...state,
        isAssignATCUnitsSuccess: action.status,
      }
    case types.SET_IS_AUTHORITIES_LOADED:
      return {
        ...state,
        isAuthoritiesLoaded: true,
      }
    case types.SET_IS_REVIEWERS_LOADED:
      return {
        ...state,
        isReviewersLoaded: true,
      }
    default:
      return state
  }
}
