export const roles = {
  OPERATOR: 'operator',
  REVIEWER: 'reviewer',
  CONSULTANT: 'consultant',
  CONTACT: 'contact',
  MONITOR: 'monitor',
  OBSERVER: 'observer',
} as const

type RoleKeys = keyof typeof roles
export type RoleValues = typeof roles[RoleKeys]

export const rolesToFilter = [roles.CONTACT, roles.MONITOR, roles.OBSERVER]
export type RolesToFilter = typeof rolesToFilter[number]

export const authRoles = [
  { name: roles.OPERATOR },
  { name: roles.REVIEWER },
  { name: roles.CONSULTANT },
  { name: roles.CONTACT },
]

export const authorityRoles = [roles.CONTACT, roles.REVIEWER, roles.OBSERVER, roles.MONITOR, roles.CONSULTANT]

export const publicRoles = [roles.OPERATOR]

export const defaultRole = roles.OPERATOR

export const roleGroups = {
  AUTHORITY: 'authority',
  OPERATOR: 'operator',
}
type RoleGroupsKeys = keyof typeof roleGroups
export type RoleGroupsValues = typeof roleGroups[RoleGroupsKeys]

export const roleLocalStorageKeys = {
  activeRoleGroup: 'active-role-group',
  activeIdentity: 'active-identity',
}
